import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthguardGuard } from "./guards/authguard.guard";
import { LoginguardGuard } from "./guards/loginguard.guard";
import { environment } from "src/environments/environment";

const routes: Routes = [
  {
    path: "",
    redirectTo: environment.mobileApp ? "intro-page" : "home",
    pathMatch: "full",
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./pages/dashboard/home/home.module").then(
        (m) => m.HomePageModule
      ),
    canLoad: [AuthguardGuard],
  },
  {
    path: "home",
    loadChildren: () =>
      import("./pages/landingpage/home/home.module").then(
        (m) => m.HomePageModule
      ),
  },
  {
    path: "authpage",
    loadChildren: () =>
      import("./pages/auth/authpage/authpage.module").then(
        (m) => m.AuthpagePageModule
      ),
    canLoad: [LoginguardGuard],
  },

  {
    path: "confirm-email",
    loadChildren: () =>
      import("./pages/auth/confirm-email/confirm-email.module").then(
        (m) => m.ConfirmEmailPageModule
      ),
    canLoad: [AuthguardGuard],
  },
  {
    path: "logout",
    loadChildren: () =>
      import("./pages/dashboard/logout/logout.module").then(
        (m) => m.LogoutPageModule
      ),
  },
  {
    path: "profile",
    loadChildren: () =>
      import("./pages/dashboard/profile/profile.module").then(
        (m) => m.ProfilePageModule
      ),
  },
  {
    path: "profilemanageaccount",
    loadChildren: () =>
      import(
        "./pages/dashboard/profile-manage-account/profile-manage-account.module"
      ).then((m) => m.ProfileManageAccountPageModule),
  },
  {
    path: "profilemanageteam",
    loadChildren: () =>
      import(
        "./pages/dashboard/profile-manage-team/profile-manage-team.module"
      ).then((m) => m.ProfileManageTeamPageModule),
  },
  {
    path: "fundraiserdonations-details/:id/:title",
    loadChildren: () =>
      import(
        "./pages/dashboard/fundraiserdonations-details/fundraiserdonations-details.module"
      ).then((m) => m.FundraiserdonationsDetailsPageModule),
  },
  {
    path: "fundraiserdonations",
    loadChildren: () =>
      import(
        "./pages/dashboard/fundraiserdonations/fundraiserdonations.module"
      ).then((m) => m.FundraiserdonationsPageModule),
  },
  {
    path: "wishlist-details/:id/:title",
    loadChildren: () =>
      import("./pages/dashboard/wishlist-details/wishlist-details.module").then(
        (m) => m.WishlistDetailsPageModule
      ),
  },
  {
    path: "wishlists",
    loadChildren: () =>
      import("./pages/dashboard/wishlists/wishlists.module").then(
        (m) => m.WishlistsPageModule
      ),
  },
  {
    path: "dashitems",
    loadChildren: () =>
      import("./pages/dashboard/dashitems/dashitems.module").then(
        (m) => m.DashitemsPageModule
      ),
  },
  {
    path: "dashitems-details/:id/:title",
    loadChildren: () =>
      import(
        "./pages/dashboard/dashitems-details/dashitems-details.module"
      ).then((m) => m.DashitemsDetailsPageModule),
  },
  {
    path: "create-causes",
    loadChildren: () =>
      import("./pages/dashboard/create-causes/create-causes.module").then(
        (m) => m.CreateCausesPageModule
      ),
  },
  {
    path: "create-causes-fundraiser-donations",
    loadChildren: () =>
      import(
        "./pages/dashboard/create-causes-fundraiser-donations/create-causes-fundraiser-donations.module"
      ).then((m) => m.CreateCausesFundraiserDonationsPageModule),
  },
  {
    path: "create-causes-wishlist",
    loadChildren: () =>
      import(
        "./pages/dashboard/create-causes-wishlist/create-causes-wishlist.module"
      ).then((m) => m.CreateCausesWishlistPageModule),
  },
  {
    path: "create-causes-dash-items",
    loadChildren: () =>
      import(
        "./pages/dashboard/create-causes-dash-items/create-causes-dash-items.module"
      ).then((m) => m.CreateCausesDashItemsPageModule),
  },
  {
    path: "fundraiserdonation-me",
    loadChildren: () =>
      import(
        "./pages/dashboard/fundraiserdonation-me/fundraiserdonation-me.module"
      ).then((m) => m.FundraiserdonationMePageModule),
  },
  {
    path: "dashitems-me",
    loadChildren: () =>
      import("./pages/dashboard/dashitems-me/dashitems-me.module").then(
        (m) => m.DashitemsMePageModule
      ),
  },
  {
    path: "wishlist-me",
    loadChildren: () =>
      import("./pages/dashboard/wishlist-me/wishlist-me.module").then(
        (m) => m.WishlistMePageModule
      ),
  },
  {
    path: "dashlists",
    loadChildren: () =>
      import("./pages/dashboard/dashlists/dashlists.module").then(
        (m) => m.DashlistsPageModule
      ),
  },
  {
    path: "message",
    loadChildren: () =>
      import("./pages/dashboard/message/message.module").then(
        (m) => m.MessagePageModule
      ),
  },
  {
    path: "notification",
    loadChildren: () =>
      import("./pages/dashboard/notification/notification.module").then(
        (m) => m.NotificationPageModule
      ),
  },
  {
    path: "wallets",
    loadChildren: () =>
      import("./pages/dashboard/wallets/wallets.module").then(
        (m) => m.WalletsPageModule
      ),
  },
  {
    path: "wallet-dashlink-receiver/:linkid",
    loadChildren: () =>
      import(
        "./pages/dashboard/wallet-dashlink-receiver/wallet-dashlink-receiver.module"
      ).then((m) => m.WalletDashlinkReceiverPageModule),
  },
  {
    path: "trivia-games",
    loadChildren: () =>
      import("./pages/dashboard/trivia-games/trivia-games.module").then(
        (m) => m.TriviaGamesPageModule
      ),
  },
  {
    path: "trivia-games-details/:id/:title",
    loadChildren: () =>
      import(
        "./pages/dashboard/trivia-games-details/trivia-games-details.module"
      ).then((m) => m.TriviaGamesDetailsPageModule),
  },
  {
    path: "edit-cause-dash-items/:id/:title",
    loadChildren: () =>
      import(
        "./pages/dashboard/edit-cause-dash-items/edit-cause-dash-items.module"
      ).then((m) => m.EditCauseDashItemsPageModule),
  },
  {
    path: "edit-cause-fundraiser-donations/:id/:title",
    loadChildren: () =>
      import(
        "./pages/dashboard/edit-cause-fundraiser-donations/edit-cause-fundraiser-donations.module"
      ).then((m) => m.EditCauseFundraiserDonationsPageModule),
  },
  {
    path: "edit-cause-wishlist/:id/:title",
    loadChildren: () =>
      import(
        "./pages/dashboard/edit-cause-wishlist/edit-cause-wishlist.module"
      ).then((m) => m.EditCauseWishlistPageModule),
  },
  {
    path: "features",
    loadChildren: () =>
      import("./pages/landingpage/features/features.module").then(
        (m) => m.FeaturesPageModule
      ),
  },
  {
    path: "fundraisers",
    loadChildren: () =>
      import("./pages/landingpage/fundraisers/fundraisers.module").then(
        (m) => m.FundraisersPageModule
      ),
  },
  {
    path: "faq",
    loadChildren: () =>
      import("./pages/landingpage/help/faq/faq.module").then(
        (m) => m.FaqPageModule
      ),
  },
  {
    path: "get-help",
    loadChildren: () =>
      import("./pages/landingpage/help/get-help/get-help.module").then(
        (m) => m.GetHelpPageModule
      ),
  },
  {
    path: "about",
    loadChildren: () =>
      import("./pages/landingpage/company/about/about.module").then(
        (m) => m.AboutPageModule
      ),
  },
  {
    path: "terms",
    loadChildren: () =>
      import("./pages/landingpage/company/terms/terms.module").then(
        (m) => m.TermsPageModule
      ),
  },
  {
    path: "privacy",
    loadChildren: () =>
      import("./pages/landingpage/company/privacy/privacy.module").then(
        (m) => m.PrivacyPageModule
      ),
  },
  {
    path: "fundraiserdonation-details-site/:id/:title",
    loadChildren: () =>
      import(
        "./pages/landingpage/explore/fundraiserdonation-details-site/fundraiserdonation-details-site.module"
      ).then((m) => m.FundraiserdonationDetailsSitePageModule),
  },
  {
    path: "fundraiserdonation-details-embed/:id/:title",
    loadChildren: () =>
      import(
        "./pages/landingpage/explore/fundraiserdonation-details-embed/fundraiserdonation-details-embed.module"
      ).then((m) => m.FundraiserdonationDetailsEmbedPageModule),
  },
  {
    path: "general-search",
    loadChildren: () =>
      import("./pages/dashboard/general-search/general-search.module").then(
        (m) => m.GeneralSearchPageModule
      ),
  },
  {
    path: "wishlist-details-site/:id/:title",
    loadChildren: () =>
      import(
        "./pages/landingpage/explore/wishlist-details-site/wishlist-details-site.module"
      ).then((m) => m.WishlistDetailsSitePageModule),
  },
  {
    path: "wishlist-details-embed/:id/:title",
    loadChildren: () =>
      import(
        "./pages/landingpage/explore/wishlist-details-embed/wishlist-details-embed.module"
      ).then((m) => m.WishlistDetailsEmbedPageModule),
  },
  {
    path: "dashitem-details-site/:id/:title",
    loadChildren: () =>
      import(
        "./pages/landingpage/explore/dashitem-details-site/dashitem-details-site.module"
      ).then((m) => m.DashitemDetailsSitePageModule),
  },
  {
    path: "dashitem-details-embed/:id/:title",
    loadChildren: () =>
      import(
        "./pages/landingpage/explore/dashitem-details-embed/dashitem-details-embed.module"
      ).then((m) => m.DashitemDetailsEmbedPageModule),
  },
  {
    path: "explore-page",
    loadChildren: () =>
      import(
        "./pages/landingpage/explore/explore-page/explore-page.module"
      ).then((m) => m.ExplorePagePageModule),
  },
  {
    path: "explore-page-dashitems",
    loadChildren: () =>
      import(
        "./pages/landingpage/explore/explore-page-dashitems/explore-page-dashitems.module"
      ).then((m) => m.ExplorePageDashitemsPageModule),
  },
  {
    path: "explore-page-wishlist",
    loadChildren: () =>
      import(
        "./pages/landingpage/explore/explore-page-wishlist/explore-page-wishlist.module"
      ).then((m) => m.ExplorePageWishlistPageModule),
  },
  {
    path: "explore-page-fundraiser",
    loadChildren: () =>
      import(
        "./pages/landingpage/explore/explore-page-fundraiser/explore-page-fundraiser.module"
      ).then((m) => m.ExplorePageFundraiserPageModule),
  },
  {
    path: "explore-page-profile/:userId/:username",
    loadChildren: () =>
      import(
        "./pages/landingpage/explore/explore-page-profile/explore-page-profile.module"
      ).then((m) => m.ExplorePageProfilePageModule),
  },
  {
    path: "profile-others/:userId/:username",
    loadChildren: () =>
      import("./pages/dashboard/profile-others/profile-others.module").then(
        (m) => m.ProfileOthersPageModule
      ),
  },
  {
    path: "no-access",
    loadChildren: () =>
      import("./pages/landingpage/no-access/no-access.module").then(
        (m) => m.NoAccessPageModule
      ),
  },
  {
    path: "intro-page",
    loadChildren: () =>
      import("./pages/mobileAppLanding/intro-page/intro-page.module").then(
        (m) => m.IntroPagePageModule
      ),
  },
  {
    path: "intro-sliders",
    loadChildren: () =>
      import(
        "./pages/mobileAppLanding/intro-sliders/intro-sliders.module"
      ).then((m) => m.IntroSlidersPageModule),
  },
  {
    path: "intro-get-started",
    loadChildren: () =>
      import(
        "./pages/mobileAppLanding/intro-get-started/intro-get-started.module"
      ).then((m) => m.IntroGetStartedPageModule),
  },

  {
    path: "raffle-me",
    loadChildren: () =>
      import("./pages/dashboard/raffle-me/raffle-me.module").then(
        (m) => m.RaffleMePageModule
      ),
  },
  {
    path: "raffle-explore",
    loadChildren: () =>
      import("./pages/dashboard/raffle-explore/raffle-explore.module").then(
        (m) => m.RaffleExplorePageModule
      ),
  },
  {
    path: "raffle-details/:id/:title",
    loadChildren: () =>
      import("./pages/dashboard/raffle-details/raffle-details.module").then(
        (m) => m.RaffleDetailsPageModule
      ),
  },
  {
    path: "raffle-details-me/:id/:title",
    loadChildren: () =>
      import(
        "./pages/dashboard/raffle-details-me/raffle-details-me.module"
      ).then((m) => m.RaffleDetailsMePageModule),
  },
  {
    path: "edit-raffle/:id/:title",
    loadChildren: () =>
      import("./pages/dashboard/edit-raffle/edit-raffle.module").then(
        (m) => m.EditRafflePageModule
      ),
  },
  {
    path: "create-raffle",
    loadChildren: () =>
      import("./pages/dashboard/create-raffle/create-raffle.module").then(
        (m) => m.CreateRafflePageModule
      ),
  },
  {
    path: "mobile-quick-update",
    loadChildren: () =>
      import(
        "./pages/auth/mobile-quick-update/mobile-quick-update.module"
      ).then((m) => m.MobileQuickUpdatePageModule),
  },
  {
    path: "mobile-explore-page",
    loadChildren: () =>
      import(
        "./pages/mobileAppLanding/mobile-explore-page/mobile-explore-page.module"
      ).then((m) => m.MobileExplorePagePageModule),
  },
  {
    path: "**",
    loadChildren: () =>
      import("./pages/landingpage/page-not-found/page-not-found.module").then(
        (m) => m.PageNotFoundPageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

/* eslint-disable @typescript-eslint/member-ordering */
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthApiserviceService } from "./services/authapiservice.service";
import { GeneralserviceService } from "./services/generalservice.service";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Platform, AlertController, NavController } from "@ionic/angular";
import { Device } from "@capacitor/device";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  private routeSub: Subscription;
  siteToken = "";
  trafficSource = "";

  constructor(
    public generalService: GeneralserviceService,
    public authApiserviceService: AuthApiserviceService,
    private route: ActivatedRoute,
    private platform: Platform,
    public router: Router,
    public alertController: AlertController,
    private navControlelr: NavController
  ) {
    this.onInit();
  }

  async onInit() {
    setTimeout(() => {
      this.saveTraffic();
      this.checkAccess();
    }, 3000);
  }

  async getParamsDelay() {
    setTimeout(async () => {
      await this.getParams();
    }, 500);
  }

  async getParams() {
    this.routeSub = await this.route.queryParams.subscribe(
      async (queryParams) => {
        // console.log(queryParams);
        this.siteToken = queryParams?.site_access_token || "";
        this.trafficSource = queryParams?.traffic_source || "";
      }
    );
  }

  async checkAccess() {
    if (environment.mobileApp) {
      this.setMobileConfig();
      // this.generalService.navigatePage("/intro-page");
      return;
    }

    if (environment.production) {
      return;
    }
    // console.log(this.siteToken);

    if (this.siteToken !== "") {
      await this.authApiserviceService.saveStagingAccess(this.siteToken);
      // console.log('Token Updated');
    }

    const hasAccess = await this.authApiserviceService.checkStagingAccess();

    if (!hasAccess) {
      this.generalService.navigatePage("/no-access");
      await this.authApiserviceService.clearStagingAccess();
    }
  }

  async setMobileConfig() {
    try {
      StatusBar.setBackgroundColor({ color: "#619043" });
      StatusBar.setStyle({ style: Style.Dark });
      this.initBackbtn();
    } catch (err) {
      console.log("Status Bar Cannot be set");
    }
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      header: "Confirm!",
      message: "Are you sure you want to Exit App",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: "Exit",
          handler: () => {
            console.log("Confirm Okay");
            navigator["app"].exitApp();
          },
        },
      ],
    });
    await alert.present();
  }

  initBackbtn() {
    this.platform.backButton.subscribeWithPriority(10, async () => {
      const currentUrl = this.router.url;
      if (currentUrl === "/dashboard" || currentUrl === "/authpage?tab=login") {
        this.presentAlertConfirm();
        //navigator['app'].exitApp();;
      } else {
        this.navControlelr.back();
      }
    });
  }

  async saveTraffic() {
    const info = await Device.getId();
    const deviceInfo = await Device.getInfo();

    this.authApiserviceService
      .saveUserTraffic({
        uuid: info.identifier,
        deviceName: deviceInfo.name || deviceInfo.manufacturer,
        deviceType: deviceInfo.platform,
        source: this.trafficSource,
      })
      .subscribe(
        (res) => {
          console.log("Traffic Logged");
        },
        (err) => {
          console.log(err);
        }
      );
  }
}
